<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure Listening: Fill in the blanks.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="12">
                    <label for="title">Enter Title</label>
                    <b-form-input v-model="fillinblank.title" placeholder="Enter Question Title"></b-form-input>
                </b-col>
                <b-col cols="6">
                    <label for="mock_ind">Select Question Type</label>
                    <b-form-select v-model="fillinblank.mock_ind" :options="dropdown"></b-form-select>
                </b-col>
            </b-row>
            <b-col cols="12">
                <div class="form-group">
                    <label for="textarea">Enter Question</label>
                    <p class="extraNote">Please replace the blank area with #blank1#, #blank2#...</p>
                    <textarea v-model="fillinblank.question_desc" class="form-control" id="textarea" rows="10"></textarea>
                </div>
            </b-col>
            <b-col class="col-12">
                    <label for="title">Select Audio</label>
                    <b-form-file
                        v-model="fillinblank.question_recording"
                        :state="Boolean(fillinblank.question_recording)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".mp3,audio/*"
                        @change="change"
                        ></b-form-file>
            </b-col>
            <b-col cols="12">
                <h5>Add Options</h5>
                <b-row  v-for="(ct,index) in options" :key="index" class="mt-4">
                    <div class="input-group col-6">
                        <input v-model="ct.blank_option" id="blank_option" type="text" class="form-control shadow-sm" name="blank_option" placeholder="Option Text">
                        <b-button :disabled="ct.blank_option ==''" v-if="ct.add" variant="light" @click="addOption(ct)">
                            <b-icon shift-v="1" scale="1.1" icon="plus" style="color: #5e72e4;"></b-icon>
                            Add
                        </b-button>
                        <b-button v-else variant="outline-danger" @click="removeOption(index)">
                            <b-icon shift-v="1" scale=".8" icon="dash-circle" variant="danger"></b-icon>
                        </b-button>
                    </div>
                    <div class="col-4" >
                        <b-form-select :id="'index-'+index" v-model="ct.blank_id" :options="blanks"></b-form-select>
                    </div>
                </b-row>
            </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(question_desc)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Parent Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
                <div class="form-group">
                    <label for="excelFile">Select Child Excel file</label>
                    <b-form-file
                        id="excelChildFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChangeChild"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read Parent File</b-button>
                <b-button :disabled="!fileChildLoad" @click="readChildFile()" class="mr-4">Read Child File</b-button>
                <b-button :disabled="!readChildFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
// import blobToBase64 from 'blob-to-base64'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            excelFile:null,
            excelChildFile:null,
            fileLoad:false,
            fileChildLoad:false,
            checkFlag:true,
            readFileFlag:false,
            readChildFileFlag:false,
            rowsLoadedArray:null,
            rowsChildLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            loadedFile:null,
            fillinblank:{
                title:'',
                question_recording:null,
                question_desc:'',
                user_id:'1',
                time_slot:'02:00',
                url:'',
                answers:null,
                question_id:0,
                mock_ind:'0'
            },
            options:[{
                blank_id:0,
                question_id:'',
                blank_option:'',
                add: true,
                correct_val_ind:'F'
            }],
            blanks:[
                {value:0,text:'select'},
                {value:1,text:'blank1'},
                {value:2,text:'blank2'},
                {value:3,text:'blank3'},
                {value:4,text:'blank4'},
                {value:5,text:'blank5'},
                {value:6,text:'blank6'},
                {value:7,text:'blank7'},
                {value:8,text:'blank8'},
                {value:9,text:'blank9'},
                {value:10,text:'blank10'}
            ],
            dropdown: [
                { value: '0', text: 'Please select an option' },
                { value: 'F', text: 'General' },
                { value: 'T', text: 'Mock Test' }
            ],
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            // {
            //     key: 'edit',
            //     label:'',
            //     sortable: false
            // },
            {
                key: 'question_id',
                label:'Delete',
                sortable: true
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            },
            {
                key: 'question_desc',
                label: 'Description',
                sortable: true,
            }
            ]
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'listeningmodule/saveAnswer',
            saveQuestion:'listeningmodule/saveQuestion',
            questionservice: 'listeningmodule/fetchQuestion',
            fileUpload:'speakmodule/uploadFile',
            deleteQuestion:'writtingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'listeningmodule/AnswerResponse',
            getQuestionList: 'listeningmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        onFileChangeChild(e){
            const file = e.target.files[0];
            this.excelChildFile=file
            this.fileChildLoad=true
            this.readChildFileFlag=false
            this.consoleLog.push('Selected Child file loaded.')
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        readChildFile(){
            if(this.excelChildFile!=null){
            readXlsxFile(this.excelChildFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel child sheet ' +rows.length +'.')
                            this.fileChildLoad =false
                            this.readChildFileFlag=true
                            this.rowsChildLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null | this.rowsChildLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                //console.log(item)
                //console.log(this.rowsChildLoadedArray)
                var options=[]
                this.rowsChildLoadedArray.forEach(element => {
                    //var question_idArray=element[0]//.split("#");
                    var eleId=element[0];
                    if(eleId==item[0])
                    {
                         var option_descArray=element[1].split("#");
                        for (let index = 0; index < option_descArray.length; index++) {
                            //const element = array[index];
                            const temp={
                            question_id:eleId,
                            blank_id:index+1,
                            blank_option:option_descArray[index],
                            add: true,
                            correct_val_ind:'T'
                        }
                        options.push(temp)
                        }
                        // const temp={
                        //     blank_id:element[1],
                        //     question_id:element[0],
                        //     blank_option:element[2],
                        //     add: true,
                        //     correct_val_ind:'T'
                        // }
                        // options.push(temp)
                    }
                });
                this.fillinblank={
                    title:item[1],
                    question_desc:item[2],
                    question_recording:item[3],
                    user_id:'1',
                    time_slot:today,
                    url:'configlisteningblankq/InsertBulkQuestion',
                    answers:options,
                    question_id:0,
                    mock_ind:'F',
                    new_ind:item[4],
                    predicted_ind:(item[5])?'Y':'N',
                    exam_ind:(item[6])?'Y':'N',
                }
                
                var findRecord=this.allQuestions.filter((item)=>{
                    return item.title.trim().toLowerCase()==this.fillinblank.title.trim().toLowerCase()
                })[0]
                ////console.log(this.fillinblank)
                if(findRecord!=null){
                    this.fillinblank.question_id=findRecord.question_id
                    ////console.log(findRecord.question_id)
                    ////console.log(this.fillinblank)
                    const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                    const param={
                            tableName:'tblListeningFillinBlankQ',
                            idName:'question_id', 
                            id:findRecord.question_id,
                            predicted_ind:this.fillinblank.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.fillinblank.exam_ind,
                            title:this.fillinblank.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                }
                else{
                    //console.log(this.fillinblank)
                    this.saveQuestion(this.fillinblank).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        id=res.data.insertId
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblListeningFillinBlankQ',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.fillinblank.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.fillinblank.exam_ind,
                            title:this.fillinblank.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                }
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.fillinblank={
                title:'',
                question_desc:'',
                question_recording:null,
                user_id:'1',
                time_slot:'10:00',
                url:'',
                answers:null,
                question_id:0,
                mock_ind:'0'
            }
            this.options=[{
                blank_id:0,
                question_id:'',
                blank_option:'',
                add: true,
                correct_val_ind:'F'
            }]
        },
        onChange(e) {
            this.loadedFile = e.target.files
        },
        onSubmit(evt) {
            evt.preventDefault()
            ////console.log(this.options)

            //var tempurl = URL.createObjectURL(this.fillinblank.question_recording);

            this.fillinblank.url = 'configlisteningblankq/createquestion'
            this.fillinblank.answers = this.options
            var vm=this
            var data={
                fileName:'ListFillinBlanks--',
                files:this.loadedFile
            }
            //console.log(data)
            vm.fillinblank.question_recording = 'Nothing'
            vm.saveQuestion(vm.fillinblank).then(()=>{
                ////console.log(vm.answerResponse())
                vm.userAnswerResponse = vm.answerResponse().data
                vm.fillinblank.question_id = vm.userAnswerResponse.insertId
                data.fileName+= vm.userAnswerResponse.insertId
                vm.fileUpload(data)
                vm.allQuestions.push( vm.fillinblank)
                vm.$parent.getQuestionTypes();
                vm.resetFrom();
                vm.dismissCountDown = vm.dismissSecs
                //alert('Data has been saved successfully.');
            });
            // fetch(tempurl).then(function(response) {
            //     if(response.ok) {
            //         return response.blob();
            //     }
            //     else{
            //         throw new Error('Network response was not ok.');
            //     }
            //     }).then(function(blob) {
            //         blobToBase64(blob, function (error, base64) {
            //             if (!error) {
            //                 //////console.log(vm)
            //                 vm.fillinblank.question_recording = base64
            //                 vm.saveQuestion(vm.fillinblank).then(()=>{
            //                     ////console.log(vm.answerResponse())
            //                     vm.userAnswerResponse = vm.answerResponse().data
            //                     vm.fillinblank.question_id = vm.userAnswerResponse.insertId
            //                     vm.allQuestions.push( vm.fillinblank)
            //                     vm.$parent.getQuestionTypes();
            //                     vm.resetFrom();
            //                     vm.dismissCountDown = vm.dismissSecs
            //                     //alert('Data has been saved successfully.');
            //                 });
            //                 return base64;
            //             }
            //             else{
            //                 ////console.log(error)
            //             }
            //         });
            //     }).catch(function(error) {
            //     //console.log('There has been a problem with your fetch operation: ', error.message);
            //     });
        },
        addOption: function (ct) {
          const tempCt= {
            blank_id:0,//this.options.length+1,
            question_id:'',
            blank_option:'',
            add: true,
            correct_val_ind:'F'
          }
          ct.add = false
          this.options.push(tempCt)
      },
      removeOption: function (index) {
          this.options.splice(index,1)
      },
      deleteRecord(id){
            var data={
                url:'configlisteningblankq/deletequestion/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
        }
    },
    watch:{
        fillinblank: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.fillinblank.title !== '' & this.fillinblank.question_desc !== '')
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configlisteningblankq/getAllQuestionsForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>

<style scoped>
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
</style>
