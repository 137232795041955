<template>
    <div>
        <b-row>
            <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure Listening missing words question/record question.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
                <b-row>
                    <b-col class="col-6">
                        <label for="title">Enter Title</label>
                        <b-form-input v-model="selectMissingWords.title" placeholder="Enter Title"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="mock_ind">Select Question Type</label>
                        <b-form-select v-model="selectMissingWords.mock_ind" :options="dropdown"></b-form-select>
                    </b-col>
                </b-row>
                <b-col class="col-12">
                    <label for="question_desc">Enter Question</label>
                    <b-form-input v-model="selectMissingWords.question_desc" placeholder="Enter Question Description"></b-form-input>
                </b-col>
                <b-col class="col-12">
                    <label for="file">Select Audio</label>
                    <b-form-file
                        v-model="selectMissingWords.question_recording"
                        :state="Boolean(selectMissingWords.question_recording)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".mp3,audio/*"
                        v-on:change="handleFileUpload"
                        ></b-form-file>
                </b-col>
                <b-col cols="12 mt-4">
                    <h5>Add Options</h5>
                <b-row  v-for="(ct,index) in options" :key="index" class="mt-4">
                        <div class="input-group col-10">
                            <input v-model="ct.option_desc" id="option_desc" type="text" class="form-control shadow-sm" name="option_desc" placeholder="Option Text">
                            <b-button :disabled="ct.option_desc.trim() ==''" v-if="ct.add" variant="light" @click="addOption(ct)">
                                <b-icon shift-v="1" scale="1.1" icon="plus" style="color: #5e72e4;"></b-icon>
                                Add
                            </b-button>
                            <b-button v-else variant="outline-danger" @click="removeOption(index)">
                                <b-icon shift-v="1" scale=".8" icon="dash-circle" variant="danger"></b-icon>
                            </b-button>
                        </div>
                        <div class="col-2 mt-2">
                            <b-form-checkbox :disabled="ct.option_desc.trim() ==''" :id="'index-'+index" v-model="ct.correct_val_ind" value='T' unchecked-value='F'>
                            Correct 
                            </b-form-checkbox>
                        </div>
                    </b-row>
                </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
         <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="multipleChoiceSingle"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(question_desc)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="multipleChoiceSingle"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Parent Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
                <div class="form-group">
                    <label for="excelFile">Select Child Excel file</label>
                    <b-form-file
                        id="excelChildFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChangeChild"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read Parent File</b-button>
                <b-button :disabled="!fileChildLoad" @click="readChildFile()" class="mr-4">Read Child File</b-button>
                <b-button :disabled="!readChildFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
// import blobToBase64 from 'blob-to-base64'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            excelFile:null,
            excelChildFile:null,
            fileLoad:false,
            fileChildLoad:false,
            checkFlag:true,
            readFileFlag:false,
            readChildFileFlag:false,
            rowsLoadedArray:null,
            rowsChildLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            loadedFile:null,
            selectMissingWords:{
                title:'',
                question_desc:'',
                question_recording:null,
                user_id:1,
                time_slot:'',
                url:'',
                answers:null,
                question_id:'',
                mock_ind:'0'
            },
            options:[{
                id:1,
                question_id:'',
                option_desc:'',
                add: true,
                correct_val_ind:'F'
            }],
            userAnswerResponse: null,
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            // {
            //     key: 'edit',
            //     label:'',
            //     sortable: false
            // },
            {
                key: 'question_id',
                label:'Delete',
                sortable: true
            },
            {
                key: 'title',
                label:'Title',
                sortable: false
            },
            {
                key: 'question_desc',
                label: 'Description',
                sortable: true,
            }
            ],
            dropdown: [
                { value: '0', text: 'Please select an option' },
                { value: 'F', text: 'General' },
                { value: 'T', text: 'Mock Test' }
            ],
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'listeningmodule/saveAnswer',
            saveQuestion:'listeningmodule/saveQuestion',
            questionservice: 'listeningmodule/fetchQuestion',
            fileUpload:'speakmodule/uploadFile',
            deleteQuestion:'writtingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'listeningmodule/AnswerResponse',
            getQuestionList: 'listeningmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        onFileChangeChild(e){
            const file = e.target.files[0];
            this.excelChildFile=file
            this.fileChildLoad=true
            this.readChildFileFlag=false
            this.consoleLog.push('Selected Child file loaded.')
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        readChildFile(){
            if(this.excelChildFile!=null){
            readXlsxFile(this.excelChildFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel child sheet ' +rows.length +'.')
                            this.fileChildLoad =false
                            this.readChildFileFlag=true
                            this.rowsChildLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null | this.rowsChildLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                //console.log(item)
                //console.log(this.rowsChildLoadedArray)
                var options=[]
                this.rowsChildLoadedArray.forEach(element => {
                    var question_idArray=element[0].split("#");
                    var eleId=question_idArray[0];
                    if(eleId==item[0])
                    {
                         var option_descArray=element[1].split("#");
                        var correct_val_indArray=element[2].split("#");
                        for (let index = 0; index < correct_val_indArray.length; index++) {
                            //const element = array[index];
                            const temp={
                            question_id:eleId,
                            id:index+1,
                            option_desc:option_descArray[index],
                            add: true,
                            correct_val_ind:correct_val_indArray[index]
                        }
                        options.push(temp)
                        }
                        // const temp={
                        //     question_id:element[0],
                        //     option_desc:element[2],
                        //     add: true,
                        //     correct_val_ind:element[3]
                        // }
                        // options.push(temp)
                    }
                });
                this.selectMissingWords={
                    title:item[2],
                    question_desc:item[3],
                    transcript:item[4],
                    question_recording:item[5],
                    slot_time:today,
                    question_id:0,
                    user_id:'1',
                    time_slot:'',
                    url:'configmissingwordsquestion/InsertBulkQuestion',
                    answers:options,
                    mock_ind:'F',
                    new_ind:item[6],
                    predicted_ind:(item[7])?'Y':'N',
                    exam_ind:(item[8])?'Y':'N',
                }
                
                var findRecord=this.allQuestions.filter((item)=>{
                    return item.title.trim().toLowerCase()==this.selectMissingWords.title.trim().toLowerCase()
                })[0]
                //console.log(this.selectMissingWords)
                if(findRecord!=null){
                    this.selectMissingWords.question_id=findRecord.question_id
                    this.selectMissingWords.url='configmissingwordsquestion/UpdateBulkQuestion'
                    this.saveQuestion(this.selectMissingWords).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        id=findRecord.question_id
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblListeningMissingWord',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.selectMissingWords.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.selectMissingWords.exam_ind,
                            title:this.selectMissingWords.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                
                }
                else{
                    // if(index<this.rowsLoadedArray.length-1){
                    //         this.saveRecordExcel(index+1)
                    //         }
                    // //console.log(this.selectMissingWords)
                    this.saveQuestion(this.selectMissingWords).then(()=>{
                        var res = this.answerResponse()
                        var id=0
                        const dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        id=res.data.insertId
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblListeningMissingWord',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.selectMissingWords.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.selectMissingWords.exam_ind,
                            title:this.selectMissingWords.title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                
                }
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
            this.selectMissingWords={
                title:'',
                question_desc:'',
                question_recording:null,
                user_id:1,
                time_slot:'',
                url:'',
                answers:null,
                question_id:'',
                mock_ind:'0'
            },
            this.options=[{
                id:1,
                question_id:'',
                option_desc:'',
                add: true,
                correct_val_ind:'F'
            }]
        },
        handleFileUpload(event) {
            this.loadedFile = event.target.files
            //this.selectMissingWords.question_recording = event.target.files[0];
        },
        onSubmit(evt) {
            evt.preventDefault()
            //////console.log(JSON.stringify(this.readaloud))
            this.selectMissingWords.url = 'configmissingwordsquestion/createquestion'
            //var tempurl = URL.createObjectURL(this.selectMissingWords.question_recording);
            var vm = this;
             var data={
                fileName:'SelectMissingWord--',
                files:this.loadedFile
            }
            //console.log(data)
            vm.selectMissingWords.question_recording = 'Nothing';
            //////console.log("MCQ Data")
            vm.selectMissingWords.answers= vm.options
            ////console.log(vm.selectMissingWords)
            vm.saveQuestion(vm.selectMissingWords).then(()=>{
                ////console.log(vm.answerResponse())
                vm.userAnswerResponse = vm.answerResponse().data
                vm.selectMissingWords.question_id = vm.userAnswerResponse.insertId
                data.fileName+= vm.userAnswerResponse.insertId
                vm.fileUpload(data)
                vm.allQuestions.push( vm.selectMissingWords)
                vm.$parent.getQuestionTypes();
                vm.resetFrom();
                vm.dismissCountDown = vm.dismissSecs
                //alert('Data has been saved successfully.');
            });
            // fetch(tempurl).then(function(response) {
            //     if(response.ok) {
            //         return response.blob();
            //     }
            //     else{
            //         throw new Error('Network response was not ok.');
            //     }
            //     }).then(function(blob) {
            //         blobToBase64(blob, function (error, base64) {
            //             if (!error) {
            //                 //base64 = base64.replace("data:audio/mpeg;base64,", "");
            //                 vm.selectMissingWords.question_recording = base64;
            //                 //////console.log("MCQ Data")
            //                 vm.selectMissingWords.answers= vm.options
            //                 ////console.log(vm.selectMissingWords)
            //                 vm.saveQuestion(vm.selectMissingWords).then(()=>{
            //                     ////console.log(vm.answerResponse())
            //                     vm.userAnswerResponse = vm.answerResponse().data
            //                     vm.selectMissingWords.question_id = vm.userAnswerResponse.insertId
            //                     vm.allQuestions.push( vm.selectMissingWords)
            //                     vm.$parent.getQuestionTypes();
            //                     vm.resetFrom();
            //                     vm.dismissCountDown = vm.dismissSecs
            //                     //alert('Data has been saved successfully.');
            //                 });
            //             }
            //             else{
            //                 ////console.log(error)
            //             }
            //         });
            //     }).catch(function(error) {
            //     //console.log('There has been a problem with your fetch operation: ', error.message);
            //     });
        },
        addOption: function (ct) {
          const tempCt= {
            id:this.options.length+1,
            question_id:'',
            option_desc:'',
            add: true,
            correct_val_ind:'F'
          }
          ct.add = false
          this.options.push(tempCt)
      },
      removeOption: function (index) {
          this.options.splice(index,1)
      },
      deleteRecord(id){
            var data={
                url:'configmissingwordsquestion/deletequestion/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
        }
    },
    watch:{
        selectMissingWords: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.selectMissingWords.title.trim() !== '' & this.selectMissingWords.question_recording !== null
                & this.selectMissingWords.question_desc!== '')
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configmissingwordsquestion/getAllQuestionsForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>
<style scoped>
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}
.vue-js-switch{
    margin-top: 0px!important;
}
</style>
