<template>
    <div class="row">
        <div class="questionList col-3">
            <b-list-group >
                <b-list-group-item  v-for="(question,index) in questionLists" :key="question.question_type_cde" class="d-flex justify-content-between align-items-center" @click="getQuestionDetail(question['question_type_cde'])" >
                    {{question["question_type_desc"]}}
                    <!-- Used this for user attempts -->
                    <b-badge variant="primary" pill>{{question['quest'+index]}}</b-badge>
                </b-list-group-item>
            </b-list-group>
        </div>
        <div class="questiondesc col-9" v-if="selectedquestion">
             <app-summarizeText v-if="selectedquestion=='13'"/>
             <app-multiple-choice-mulit v-if="selectedquestion=='14'"/>
             <app-multiple-choice-single v-if="selectedquestion=='15'"/>
             <app-highlight-correct-summary v-if="selectedquestion=='16'"/>
             <app-select-missing-words v-if="selectedquestion=='17'"/>
             <app-fillinblanks v-if="selectedquestion=='18'"/>
             <app-high-highlight-incorrect-words v-if="selectedquestion=='19'"/>
             <app-write-from-dectation v-if="selectedquestion=='20'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import HighlightCorrectSummary from './highlightCorrectSummary.vue'
import MultipleChoiceMulit from './multipleChoiceMulit.vue'
import MultipleChoiceSingle from './multipleChoiceSingle.vue'
import summarizeText from './summarizeText'
import selectMissingWordsVue from './selectMissingWords.vue'
import fillinblanks from './Listfillinblank'
import HighlightIncorrectWords from './highlightIncorrectWords.vue'
import WriteFromDectation from './writeFromDectation.vue'
export default {
    components:{
        'app-summarizeText':summarizeText,
        'app-multiple-choice-mulit':MultipleChoiceMulit,
        'app-multiple-choice-single':MultipleChoiceSingle,
        'app-highlight-correct-summary':HighlightCorrectSummary,
        'app-select-missing-words':selectMissingWordsVue,
        'app-fillinblanks':fillinblanks,
        'app-high-highlight-incorrect-words':HighlightIncorrectWords,
        'app-write-from-dectation':WriteFromDectation
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            selectedquestion: null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'createquestion/fetchQuestion'
        }),
        ...mapGetters({
            getQuestionList: 'createquestion/questions'
        }),
        getQuestionDetail(questionId){
            this.selectedquestion = questionId;
            ////console.log(this.selectedquestion);
        },
        getQuestionTypes(){
            this.questionservice('getquestiontypes/getlisteningmodules').then(()=>{
            this.questionLists = this.getQuestionList().data
            ////console.log(this.questionLists)
        })
        }
    },
    beforeMount(){
        this.getQuestionTypes()
    }
}
</script>
<style scoped>

</style>
<style>
.btn-light, .btn-outline-danger
{

/* padding: 3px 7px!important; */
    border: 1px solid #ced4da!important;
    color: #3f6ad8!important;
    /* color: rgb(94, 114, 228); */
    /* margin-left: 5px; */
    /* margin-top: 1px; */
    /* margin-bottom: 1px; */
    background: #fff!important;
}
/* .btn-outline-danger:hover, .btn-outline-danger:active, .btn-outline-danger:focus
{
    color:#dc3545!important;
    background: #fff!important;
} */
.btn-light:hover, .btn-light:active, .btn-light:focus
{
    color: rgb(94, 114, 228);
}
.btn-light:not(:disabled)
{
    border: 1px solid #5e72e4 !important;
}
.btn-outline-danger:not(:disabled)
{
    border: 1px solid #dc3545 !important;
}
.btn-light:not(:disabled)
{
    /* border-left: 0px!important; */
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
}
.extraNote
{
    color:#ccc;
}
button.btn-outline-warning, button.btn-outline-success
{
    font-size: .8rem;
    font-size: 1rem;
    padding: 0.375rem 0.5rem;
}
</style>