<template>
    <div>
        <b-row>
             <b-col cols="10">
                    <h4 v-if="checkFlag">Please configure summarize text question/record question.</h4>
                    <h4 v-else>Please select excel sheet for bulk question saving</h4>
                </b-col>
                <b-col cols="2">
                    <toggle-button :width="75" :height="28" v-model="checkFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'enter', unchecked: 'load'}"/>
                </b-col>
            <b-form v-if="checkFlag" class="col-12"  @submit="onSubmit" @reset="onReset">
                <b-row>
                    <b-col cols="6">
                        <label for="title">Enter Title</label>
                        <b-form-input v-model="summarizetext.question_title" placeholder="Enter Question Title"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label for="mock_ind">Select Question Type</label>
                        <b-form-select v-model="summarizetext.mock_ind" :options="dropdown"></b-form-select>
                    </b-col>
                </b-row>
                <b-col cols="12">
                    <label for="transcript">Trascript</label>
                    <textarea v-model="summarizetext.transcript" class="form-control" id="transcript" rows="3"></textarea>
                </b-col>
                <b-col class="col-12">
                    <label for="file">Select Audio</label>
                    <b-form-file
                        v-model="summarizetext.question_recording"
                        :state="Boolean(summarizetext.question_recording)"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".mp3,audio/*"
                        v-on:change="handleFileUpload">
                    </b-form-file>
                </b-col>
                <div class="col-6 displayInline" align="right">
                    <b-button type="submit" variant="primary" :disabled="allFilled != true">{{buttonText}}</b-button>
                </div>
                <div class="col-6 displayInline" align="left">
                    <b-button type="reset" variant="outline-danger">Reset</b-button>
                </div>
            </b-form>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-alert
                :show="dismissCountDown"
                dismissible
                variant="outline-success"
                @dismissed="dismissCountDown=0"
                
                >
                Record saved successfully, dismiss in {{ dismissCountDown }} seconds...
                </b-alert>
            </b-col>
        </b-row>
        <b-row v-if="checkFlag" class="mt-4 ml-2 mr-2">
            <b-col cols="12">
                <b-table
                    id="summarizedTaxGrid"
                    :items="allQuestions"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :fields="fields"
                    sort-icon-left
                    small
                    outlined
                    responsive
                    >
                    <template #cell(question_id)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <b-button variant="outline-warning" @click="deleteRecord(data.value)">
                        <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                    <!-- <a href="#">{{data.value}}</a> -->
                </template>
                <template #cell(transcript)="data">
                    <!-- `data.value` is the value after formatted by the Formatter -->
                    <read-more class="readmoreDiv" more-str="read more" :text="data.value" link="#" less-str="read less" :max-chars="100"></read-more>
                    <!-- <p class="transcript">{{data.value}}</p> -->
                </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="summarizedTaxGrid"
                    ></b-pagination>
            </b-col>
        </b-row>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
                
                <div class="form-group">
                    <label for="excelFile">Select Excel file</label>
                    <b-form-file
                        id="excelFile"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept=".xlsx"
                        @change="onFileChange"></b-form-file>
                </div>
            </div>
            <div class="col-12 text-center">
                <b-button :disabled="!fileLoad" @click="readFile()" class="mr-4">Read File</b-button>
                <b-button :disabled="!readFileFlag" variant="primary" @click="saveRecordExcel(1)">{{saveRecord}}</b-button>
            </div>
        </div>
        <div v-if="!checkFlag" class="row mt-4 ml-2 mr-2">
            <div class="col-12">
            <ul id="console-textarea" >
                <li v-for="(item,index) in consoleLog" :key="index">
                    {{item}}
                </li>
            </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'
import readXlsxFile from 'read-excel-file'
// import blobToBase64 from 'blob-to-base64'
export default {
    data(){
        return{
            buttonText:'Save',
            allFilled: false,
            dismissSecs: 5,
            dismissCountDown: 0,
            loadedFile:null,
            excelFile:null,
            fileLoad:false,
            checkFlag:true,
            readFileFlag:false,
            rowsLoadedArray:null,
            saveRecord:'Save Record',
            consoleLog:['Log Info.'],
            summarizetext:{
                question_title: '',
                question_recording:null,
                user_id:'1',
                time_slot:'',
                transcript:'',
                url:'',
                mock_ind:'0'
            },
            userAnswerResponse: null,
            allQuestions:null,
            perPage: 8,
            currentPage: 1,
            fields: [
            // {
            //     key: 'edit',
            //     label:'',
            //     sortable: false
            // },
            {
                key: 'question_id',
                label:'Delete',
                sortable: true
            },
            {
                key: 'question_title',
                label:'Title',
                sortable: false
            },
            {
                key: 'transcript',
                label: 'Transcript',
                sortable: true,
            }
            ],
            dropdown: [
                { value: '0', text: 'Please select an option' },
                { value: 'F', text: 'General' },
                { value: 'T', text: 'Mock Test' }
            ],
        }
    },
    methods:{
        ...mapActions({
            saveAnswer: 'listeningmodule/saveAnswer',
            saveQuestion:'listeningmodule/saveQuestion',
            questionservice: 'listeningmodule/fetchQuestion',
            fileUpload:'speakmodule/uploadFile',
            deleteQuestion:'writtingmodule/deleteQuestion'
        }),
        ...mapGetters({
            answerResponse: 'listeningmodule/AnswerResponse',
            getQuestionList: 'listeningmodule/questions'
        }),
        onFileChange(e) {
            const file = e.target.files[0];
            this.excelFile=file
            this.fileLoad =true
            this.readFileFlag=false
            this.consoleLog.push('Selected file loaded.')
            // this.loadedFile = e.target.files
            // this.fileUrl = URL.createObjectURL(file);
        },
        readFile(){
            if(this.excelFile!=null){
            readXlsxFile(this.excelFile).then((rows) => {
                            //console.log(rows)
                            this.consoleLog.push('Total records retrieve from excel sheet ' +rows.length +'.')
                            this.fileLoad =false
                            this.readFileFlag=true
                            this.rowsLoadedArray=rows
                        })
            }
        },
        saveRecordExcel(index){
            if(this.rowsLoadedArray==null)
            {
                return this.$alert('No record found')
            }
            else{
                let today = new Date().toLocaleDateString()
                var item=this.rowsLoadedArray[index]
                this.summarizetext={
                    question_id:0,
                    question_title: item[1],
                    question_recording:"audio\\ssts\\"+item[3],
                    transcript:item[2],
                    new_ind:item[4],
                    user_id:'2',
                    predicted_ind:(item[5])?'Y':'N',
                    exam_ind:(item[6])?'Y':'N',
                    reading_slot_time:today,
                    speaking_slot_time:'00:40',
                    mock_ind:'F',
                    url:''
                }
                var findRecord=this.allQuestions.filter((item)=>{
                    return item.question_title.trim().toLowerCase()==this.summarizetext.question_title.trim().toLowerCase()
                })[0]
                if(findRecord!=null){

                    var dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                    const param={
                            tableName:'tblsummarizespokenquestion',
                            idName:'question_id', 
                            id:findRecord.question_id,
                            predicted_ind:this.summarizetext.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.summarizetext.exam_ind,
                            title:this.summarizetext.question_title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                }
                else {
                    this.summarizetext.url='configurespokenquestion/InsertBulkSpokenQuestion'
                
                this.saveQuestion(this.summarizetext).then(()=>{
                        var res = this.answerResponse()
                        var id=res.data.insertId
                        var dte=new Date().toISOString().slice(0, 19).replace('T', ' ');
                        this.consoleLog.push(res.message)
                        const param={
                            tableName:'tblsummarizespokenquestion',
                            idName:'question_id', 
                            id:id,
                            predicted_ind:this.summarizetext.predicted_ind,
                            predicted_date:dte,
                            exam_ind:this.summarizetext.exam_ind,
                            title:this.summarizetext.question_title,
                            exam_date:dte,
                            type:'single',
                            url:'updatequestion/status',
                        }
                        //console.log(param)
                        this.saveQuestion(param).then(()=>{
                            var resStatus = this.answerResponse()
                            this.consoleLog.push(resStatus.message)
                            if(index<this.rowsLoadedArray.length-1){
                            this.saveRecordExcel(index+1)
                            }
                        })
                    });
                }
            }
        },
        onReset(evt) {
            evt.preventDefault()
            this.resetFrom()
        },
        resetFrom(){
           this.summarizetext={
                question_title: '',
                question_recording:null,
                user_id:'1',
                time_slot:'',
                transcript:'',
                url:'',
                mock_ind:'0'
            }
        },
        handleFileUpload(event) {
            this.loadedFile=event.target.files
            //this.summarizetext.question_recording = event.target.files[0];
        },
        onSubmit(evt) {
            evt.preventDefault()
            //////console.log(JSON.stringify(this.readaloud))
            this.summarizetext.url = 'configurespokenquestion/createspokenquestion'
            ////console.log(this.summarizetext.question_recording)
            //var tempurl = URL.createObjectURL(this.summarizetext.question_recording);
            var vm = this;
             var data={
                fileName:'ssts--',
                files:this.loadedFile
            }
            //console.log(data)
             vm.summarizetext.question_recording = 'Nothing'
            ////console.log(vm.summarizetext)
            vm.saveQuestion(vm.summarizetext).then(()=>{
                ////console.log(vm.answerResponse())
                vm.userAnswerResponse = vm.answerResponse().data
                vm.summarizetext.question_id = vm.userAnswerResponse.insertId
                data.fileName+= vm.userAnswerResponse.insertId
                vm.fileUpload(data)
                vm.allQuestions.push( vm.summarizetext)
                vm.$parent.getQuestionTypes();
                vm.resetFrom();
                vm.dismissCountDown = vm.dismissSecs
                //alert('Data has been saved successfully.');
            });
            ////console.log(tempurl)
            // fetch(tempurl).then(function(response) {
            //     if(response.ok) {
            //         return response.blob();
            //     }
            //     else{
            //         throw new Error('Network response was not ok.');
            //     }
            //     }).then(function(blob) {
            //         blobToBase64(blob, function (error, base64) {
            //             if (!error) {
            //                 ////console.log("Test")
            //                // base64 = base64.replace("data:audio/mpeg;base64,", "");
            //                 vm.summarizetext.question_recording = base64
            //                 ////console.log(vm.summarizetext)
            //                 vm.saveQuestion(vm.summarizetext).then(()=>{
            //                     ////console.log(vm.answerResponse())
            //                     vm.userAnswerResponse = vm.answerResponse().data
            //                     vm.summarizetext.question_id = vm.userAnswerResponse.insertId
            //                     vm.allQuestions.push( vm.summarizetext)
            //                     vm.$parent.getQuestionTypes();
            //                     vm.resetFrom();
            //                     vm.dismissCountDown = vm.dismissSecs
            //                     //alert('Data has been saved successfully.');
            //                 });
            //                 return base64;
            //             }
            //             else{
            //                 ////console.log(error)
            //                 return null;
            //             }
            //         })
            //     }).catch(function(error) {
            //     //console.log('There has been a problem with your fetch operation: ', error);
            //     });
            //this.resetFrom();
           // alert('Data has been saved successfully.');
            
        },
        deleteRecord(id){
            var data={
                url:'configurespokenquestion/deletespokenquestion/id='+id
            }
            var vm = this
             this.deleteQuestion(data).then(()=>{
                vm.allQuestions = this.allQuestions.filter(function (item) {
                return item.question_id != id;
                })
                ////console.log(vm.allQuestions)
                vm.$parent.getQuestionTypes();
            });
            ////console.log(id)
        }
    },
    watch:{
        summarizetext: {
            deep: true,
            handler () {
                this.allFilled = false
                if(this.summarizetext.question_title.trim() !== '' )
                {
                    this.allFilled = true
                }
            }
        }
    },
    computed: {
      rows() {
          if(this.allQuestions == null){
              return 0
          }
          else{
              return this.allQuestions.length
          }
      }
    },
    beforeMount(){
        this.allQuestions = null
        this.questionservice('configurespokenquestion/getAllSpokenQuestionsForGrid').then(()=>{
            this.allQuestions = this.getQuestionList().data
            ////console.log(this.allQuestions)
        })
    },
}
</script>
<style scoped>
textarea {
    height: 40vh;
    width: 100%;
    border: 1px solid #ccc;
    resize: none;
}
label
{
    margin-top: 10px;
text-transform: capitalize;
font-weight: 500;
}

</style>
<style>
.readmoreDiv p{
margin-bottom: 0px;
}
.btn-outline-danger
{
    border:none!important;
}
</style>